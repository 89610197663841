<template>
  <a-modal
      :width="600"
      v-model="showPopup"
      :title="titleText"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <a-form-model
        ref="ruleForm"
        :model="params"
        v-bind="layout"
    >
      <a-form-item label="选择大币种">
        <a-select
            allowClear
            showSearch
            class="w-300 ml-5 mb-5"
            v-model="params.coinId"
            :dropdownMatchSelectWidth="false"
            @search="coinSearch($event)"
            @change="handleSelectedBigCoin($event)"
            :filter-option="untils.filterOption"
            placeholder="请选择大币种"
        >
          <a-select-option
              v-for="item of bigCoinList"
              :key="item.id"
              :value="item.id"
          >{{ computedShowBigCoinSelectedList(item) }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="选择小币种">
        <a-select
            allowClear
            showSearch
            class="w-300 ml-5 mb-5"
            :dropdownMatchSelectWidth="false"
            v-model="params.coinItemId"
            :filter-option="untils.filterOption"
            @change="handleChangeSmallCoin($event)"
            placeholder="请选择小币种"
        >
          <a-select-option
              v-for="item of smallCoinList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="类别名称">
        <a-input
            allowClear
            type="text"
            v-model="params.classfyName"
            class="w-300 ml-10"
            placeholder='请输入类别名称'
        ></a-input>
      </a-form-item>
      <a-form-item label="选择版别" v-if="params.coinId && params.coinItemId">
        <a-select
            allowClear
            showSearch
            mode="multiple"
            class="w-300 ml-5 mb-5"
            @search="searchGetVersionListEvent($event)"
            :dropdownMatchSelectWidth="false"
            v-model="selectedVersionList"
            :filter-option="untils.filterOption"
            placeholder="选择版别"
        >
          <a-select-option
              v-for="item of versionCoinList"
              :key="item.id"
              :value="item.id"
          >{{ item.coinKindVersionName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="类别描述">
        <a-textarea
            v-model="params.classfyDesc"
            class="w-300 ml-10"
            placeholder="类别描述"
            :rows="4" />
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import _bigOrSmallCoinByVersion from "@/_mixins/_bigOrSmallCoinByVersion"
import untils from "@/untils"
import {getVersionListApi} from "@/views/cmsPage/currencyMannage/_apis"
import {
  postEditorClassifyByAddApi,
  postEditorClassifyByUpdateApi
} from "@/views/cmsPage/currencyMannage/versionClassifyManage/_apis"
import _bigCoinShowSelectList from "@/_mixins/_bigCoinShowSelectList"

export default {
  mixins: [_bigOrSmallCoinByVersion, _bigCoinShowSelectList],
  data() {
    return {
      untils,
      titleText: '新增类别',
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      showPopup: false,
      versionParams: {
        isCoinVersionClassfy: false,
        coinId: undefined,
        coinItemId: undefined,
        versionName: undefined
      },
      selectedVersionList: [],
      params: {
        id: undefined,
        coinId: undefined,
        coinItemId: undefined,
        classfyName: undefined,
        classfyDesc: undefined,
        coinItemVersionIdList: []
      },
      bigCoinList: [],
      smallCoinList: [],
      versionCoinList: [],
      isEditOrAdd: 'add'
    }
  },
  methods: {
    async show(data) {
      if (data && data.id) {
        this.titleText = '编辑类别'
        this.isEditOrAdd = 'edit'
        this.$loading.show()
        await this.setData(data)
      }
      this.showPopup = true
    },
    /** 设置回显数据 */
    async setData(data) {
      this.params.id = data.id
      this.params.coinId = data.coinId
      this.versionParams.coinId = data.coinId
      this.params.coinItemId = data.coinItemId
      this.versionParams.coinItemId = data.coinItemId
      this.params.classfyName = data.classfyName
      this.params.classfyDesc = data.classfyDesc
      await this.coinSearch(data.coinIdName)
      await this.handleSelectedBigCoin(data.coinId)
      await this.handleChangeSmallCoin(data.coinItemId)
      this.$loading.hide()
      this.selectedVersionList = data.versionVoList.map(el => el.id)
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.params.coinId) return this.$message.warn('请选择大币种')
      if (!this.params.coinItemId) return this.$message.warn('请选择小币种种')
      if (!this.selectedVersionList.length) return this.$message.warn('请选择版别')
      if (!this.params.classfyName) return this.$message.warn('请输入类别名称')
      this.params.coinItemVersionIdList = this.selectedVersionList
      let res
      this.$loading.show()
      if (this.isEditOrAdd === 'add') {
        delete this.params.id
        res = await postEditorClassifyByAddApi(this.params)
      } else {
        res = await postEditorClassifyByUpdateApi(this.params)
      }
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('成功')
      this.$emit('success')
      this.handleCancel()
    },
    /** 选择大币种 */
    async handleSelectedBigCoin(id) {
      if (id) {
        await this.coinItemSearchByBigCoinIdOrName()
      } else {
        this.params.coinId = undefined
        this.params.coinItemId = undefined
        this.coinSid = undefined
        this.bigCoinList = []
        this.smallCoinList = []
        this.versionCoinList = []
        this.selectedVersionList = []
      }
    },
    /** 选择小币种 */
    async handleChangeSmallCoin(id) {
      if (id) {
        await this.searchGetVersionListEvent()
      } else {
        this.versionCoinList = []
        this.selectedVersionList = []
      }
    },
    /** 获取版别列表 */
    async searchGetVersionListEvent(str) {
      this.versionParams.versionName = str
      this.versionParams.coinId = this.params.coinId
      this.versionParams.coinItemId = this.params.coinItemId
      const res = await getVersionListApi(this.versionParams)
      this.$loading.hide()
      if (res.status !== '200') return
      this.versionCoinList = res.data.records
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        id: undefined,
        coinId: undefined,
        coinItemId: undefined,
        classfyName: undefined,
        classfyDesc: undefined,
        coinItemVersionIdList: []
      }
      this.versionParams = {
        isCoinVersionClassfy: false,
        coinId: undefined,
        coinItemId: undefined,
        versionName: undefined,
      }
      this.bigCoinList = []
      this.smallCoinList = []
      this.versionCoinList = []
      this.selectedVersionList = []
      this.titleText = '新增类别'
      this.isEditOrAdd = 'add'
      this.showPopup = false
    },
  }
}
</script>
<style scoped lang="scss">
</style>